import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentDesktop: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M6.75 22a.75.75 0 01-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 01-2.245-2.096L2 15.752V5.25a2.25 2.25 0 012.096-2.245L4.25 3h15.499a2.25 2.25 0 012.245 2.096l.005.154v10.502a2.25 2.25 0 01-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 01.102 1.494L17.25 22H6.75zm7.248-3.998h-4l.001 2.498h4l-.001-2.498zM19.748 4.5H4.25a.75.75 0 00-.743.648L3.5 5.25v10.502c0 .38.282.694.648.743l.102.007h15.499a.75.75 0 00.743-.648l.007-.102V5.25a.75.75 0 00-.648-.743l-.102-.007z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentDesktop
